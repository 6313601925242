.iconStack {
    display: flex;
    flex-direction: row;

    .imgWrap {
        border-radius: 50%;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        img {
            object-fit: contain;
            object-position: center;
        }

        .icon {
            border-radius: 50%;

            img {
                width: var(--size, 18px);
                height: var(--size, 18px);
            }
        }

        &:hover {
            z-index: 1;
            scale: 1.2;
        }
    }
}
