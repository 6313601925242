.tooltip {
    word-break: break-word;
    white-space: normal;
    text-align: left;
}

.labelWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 4px;
    white-space: nowrap;

    .label {
        font-size: 12px;
        font-weight: bold;
        color: var(--c-contrast-3);
        white-space: nowrap;
    }
}
