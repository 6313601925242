// -----------------------------------------
// -----------------UTIL--------------------
// -----------------------------------------

.btn {
    cursor: pointer;
    color: var(--c-bg);
    border: none;
    background-color: var(--c-fg);
    padding: 12px 24px;
    width: var(--width, 100%);
    text-align: center;
    font-weight: bold;
    display: block;
    transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    white-space: nowrap;
    --size: 16px;
    --color: var(--c-bg);

    font-size: 14px;

    @media (min-width: 960px) {
        font-size: 12px;
    }

    span {
        position: absolute;
        top: 50%;
        right: 20px;
        opacity: 0;
        transform: translateY(-40%) translateX(-10px) rotate(-45deg);
        transform-origin: center center;
        transition:
            transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275),
            opacity 0.2s linear;
    }

    &:hover {
        transform: scale(1.04);

        span {
            opacity: 1;
            transform: translateY(-50%) rotate(-45deg);
        }
    }
}

.btnBlue {
    background-color: var(--c-blue);
    color: var(--c-lightest);
}

.img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

// -----------------------------------------
// -----------------PAGE--------------------
// -----------------------------------------

.vsp {
    padding-top: clamp(80px, 12vw, 110px);
    overflow: hidden;
    position: relative;
    margin-bottom: 100px;

    --section-space: 60px;

    h2 {
        font-weight: 700 !important;
        font-size: 38px !important;
        line-height: 1.4 !important;
        color: var(--c-grey-80) !important;
    }

    opacity: 0;
    animation: FadeIn 0.4s linear 0.1s forwards;

    @keyframes FadeIn {
        to {
            opacity: 1;
        }
    }
}

//vsp

.floatingIcons {
    width: 100%;
    user-select: none;
    margin-top: 80px;

    &.withGradient::after {
        content: '';
        position: absolute;
        inset: 0 0 -16px 0;
        z-index: 2;
        background-image: var(--overflow-gradient-vert);
        pointer-events: none;
    }

    .move {
        display: inline-flex;
        width: min-content;
        min-width: 100%;
        height: 100%;
        animation: IconSlide 200s linear forwards infinite;
    }

    @keyframes IconSlide {
        from {
            transform: translateX(16px);
        }

        to {
            transform: translateX(-50%);
        }
    }

    .icons {
        display: flex;
        gap: 32px;
    }

    .iconGroup {
        display: flex;
        gap: 32px;
    }

    .iconWrap {
        width: clamp(60px, 10vw, 80px);
        height: clamp(60px, 10vw, 80px);
        flex-shrink: 0;
        flex: 1;

        &:nth-of-type(odd) {
            transform: translateY(-100%);
        }

        .vspTag {
            position: absolute;
            width: clamp(24px, 4vw, 28px);
            height: clamp(24px, 4vw, 28px);
            right: 0;
            bottom: 0;
            transform: translateX(20%);
        }
    }

    .iconInner {
        width: 100%;
        height: 100%;
        background-color: var(--c-grey-20);
        // border: var(--c-fg) 2px solid;
        border-radius: 50%;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

//floating icons

.hero {
    padding: 0 var(--p-page-sides);
    z-index: 2;

    .container {
        margin-top: -20px;
    }

    .treatment {
        margin-bottom: 32px;

        .vspTag {
            --size: 64px;

            & > span {
                display: inline-block;
                height: 32px;
            }
        }
    }

    //treatment

    .stats {
        margin-bottom: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        text-align: left;
        max-width: calc(280px * 2);

        @media (max-width: 600px) {
            grid-template-columns: 1fr;
            row-gap: 24px;
            max-width: 280px;
            margin-bottom: 24px;
        }
    }

    .buttons {
        display: grid;
        gap: 40px;
        grid-template-columns: 1fr 1fr;
        max-width: calc(280px * 2);

        .btn {
            width: 100%;
        }

        @media (max-width: 600px) {
            grid-template-columns: 1fr;
            row-gap: 24px;
            max-width: 280px;
            margin-bottom: 24px;
        }
    }

    @media (min-width: 960px) {
        .container {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 40px;
        }

        .treatment .title {
            font-size: clamp(40px, 4vw, 54px);
        }

        .right {
            display: flex;

            height: 100%;
            flex-direction: column;
            justify-content: center;
        }

        .btn {
            width: auto;
        }
    }
}

//hero

.features {
    padding: 0 var(--p-page-sides);

    .feature {
        display: inline-block;
        margin-top: 60px;
        padding-right: 40px;
        max-width: 380px;

        .label {
            font-weight: bold;
            color: var(--c-grey-80);
            margin-top: 0;
        }

        p {
            margin-top: 8px;
            color: var(--c-grey-60);
        }
    }

    @media (min-width: 768px) {
        padding-bottom: 32px;

        .container {
            display: grid;
            grid-auto-flow: column;
            gap: 60px;

            .feature {
                padding: 0;
            }
        }
    }

    @media (min-width: 960px) {
        .container {
            text-align: center;
        }
    }
}

//features

.madeEasy {
    padding: 0 var(--p-page-sides);
    margin-top: 80px;

    .gradient {
        width: clamp(600px, 80vw, 1000px);
        height: clamp(600px, 80vw, 1000px);
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(50%);
        z-index: 1;
        opacity: 0.4;
        pointer-events: none;
    }

    .media {
        padding: 40px;
        display: block;
        margin-bottom: 60px;
        opacity: 0;
        visibility: hidden;

        .rotatingIcons {
            width: 190px;
            height: 190px;

            .icon {
                --size: 56px;
                width: var(--size);
                height: var(--size);
                position: absolute;
                background-color: var(--c-grey-20);
                border-radius: 50%;
                display: flex;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
                transform: translate3d(-50%, -50%, 0);

                .img {
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            .icon:not(.iconCenter) {
                & > span {
                    position: absolute;
                    --size: 24px;
                    right: 0;
                    bottom: 0;
                }
            }

            .iconCenter {
                left: 50%;
                top: 50%;
                --size: 52px;
            }

            .iconFirst {
                left: 50%;
                top: 0;
            }

            .iconSecond {
                left: 100%;
                top: 25%;
            }

            .iconThird {
                left: 100%;
                top: 75%;
            }

            .iconFourth {
                left: 50%;
                top: 100%;
            }

            .iconFifth {
                left: 0;
                top: 75%;
            }

            .iconSixth {
                left: 0;
                top: 25%;
            }
        }
    }

    //media

    .treatment {
        max-width: 520px;
    }

    @media (min-width: 768px) {
        margin-top: 60px;

        .container {
            display: flex;
            gap: 60px;
            align-items: center;

            .media {
                margin: 0;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

//madeEasy

.safeStaking {
    padding: 0 var(--p-page-sides);
    margin-top: 60px;

    .treatment {
        max-width: 520px;
    }

    @media (min-width: 768px) {
        margin-top: 100px;

        .treatment {
            padding-right: 80px;
        }
    }

    @media (min-width: 960px) {
        .treatment {
            padding-right: 0;
        }
    }
}

.industryStandard {
    padding: 0 var(--p-page-sides);
    margin-top: 60px;
    z-index: 3;

    .treatment {
        max-width: 620px;
    }

    @media (min-width: 768px) {
        margin-top: 140px;

        .container {
            text-align: center;

            .treatment {
                display: inline-block;
            }
        }
    }
}

.methodologies {
    padding: 0 var(--p-page-sides);
    margin-top: 80px;
    z-index: 3;

    h2 {
        margin-bottom: 8px !important;
    }

    h3 {
        margin-bottom: 12px;
        color: var(--c-grey-80);
    }

    p {
        color: var(--c-mid);
    }

    .srRating,
    .valueRating {
        margin-top: 24px;
        padding: 24px;
        border-radius: 8px;
        background-color: var(--c-contrast-1);
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .valueRating {
        margin-top: 40px;
    }

    .ratingHeader {
        line-height: 1.25;
        grid-area: header;
        white-space: nowrap;
    }

    .ratingFeatures {
        color: var(--c-grey-60);
        font-weight: bold;
        line-height: 2.5;
        grid-area: features;
        white-space: nowrap;

        .ratingFeature {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .ratingContent {
        color: var(--c-grey-60);
        grid-area: content;
    }

    .btn {
        grid-area: button;
    }

    @media (min-width: 768px) {
        h3 {
            font-size: 20px;
        }

        .srRating,
        .valueRating {
            padding: 36px;
            display: grid;
            gap: 24px 80px;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto 1fr auto;
            grid-template-areas:
                'header content'
                'features content'
                'button button';
        }

        .btn {
            margin-top: 24px;
        }
    }

    @media (min-width: 960px) {
        .srRating,
        .valueRating {
            padding: 48px;
            grid-template-columns: auto auto;
            grid-template-rows: auto auto auto;
            grid-template-areas:
                'header content'
                'features content'
                'button content';
        }
    }

    @media (min-width: 1100px) {
        .srRating,
        .valueRating {
            padding: 60px;
            gap: 24px 60px;
            grid-template-columns: 240px 220px minmax(200px, 50%);
            grid-template-rows: 1fr auto;
            grid-template-areas:
                'header features content'
                'button features content';
        }
    }
}

//methodologies

.diligenceMonitoring {
    padding: 0 var(--p-page-sides);
    margin-top: 80px;

    & > div {
        display: flex;
        flex-direction: column;
        gap: 60px;

        @media (min-width: 960px) {
            flex-direction: row;
        }
    }

    .monitoring,
    .diligence {
        flex-basis: 50%;
    }
}

.trustedBy {
    background-color: var(--c-contrast-1);
    padding: 60px var(--p-page-sides);
    margin-top: 80px;

    & > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    .logo {
        height: 80px;
        width: 100px;
    }

    .img {
        object-fit: contain;
        border-radius: 0;
    }
}

//partners

.marketLeading {
    padding: 0 var(--p-page-sides);
    margin-top: 80px;

    & > div {
        text-align: center;
    }

    .treatment {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            max-width: 620px;
        }

        a {
            margin-top: 36px;
            width: 150px;
        }
    }
}

//market leading

.testimonials {
    margin-top: 80px;
    overflow: hidden;
    text-align: center;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 80px;
        background-image: var(--overflow-gradient);
        z-index: 2;
        pointer-events: none;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 80px;
        background-image: var(--overflow-gradient-pre);
        pointer-events: none;
        z-index: 2;
    }

    .gradient {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
        opacity: 0.4;
        pointer-events: none;
    }

    h2 {
        color: var(--c-dark);
        margin-bottom: 60px;
    }

    .testimonialWrap {
        display: flex;
        gap: 40px;
        padding: 0 40px;
        overflow-x: auto;
        padding-right: 80px;
        padding-bottom: 40px;
    }

    ::-webkit-scrollbar {
        height: 8px;
        top: 40px;
        display: block;
    }

    ::-webkit-scrollbar-track {
        height: 1px;
        background-color: var(--c-grey-20);
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--c-grey-40);
        border-radius: 4px;
    }
}

//testimonials

.testimonial {
    display: inline-flex;
    text-align: left;
    gap: 20px;
    padding: 16px 0;
    z-index: 2;

    .content {
        background-color: var(--c-contrast-1);
        border-radius: 40px;
        padding: 40px;
        color: var(--c-grey-60);
        font-size: 14px;
        min-width: 300px;
        max-width: 380px;
        align-self: flex-start;
        // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }

    .triangle {
        width: 0;
        height: 0;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 20px solid var(--c-contrast-1);
        position: absolute;
        top: 28px;
        right: -12px;
    }

    .details {
        max-width: 120px;
        min-width: 120px;
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .user {
            width: 60px;
            height: 60px;
            background-color: var(--c-contrast-1);
            border-radius: 50%;
            margin-bottom: 12px;
            overflow: hidden;
        }

        .title {
            font-size: 14px;
            color: var(--c-contrast-4);
            font-weight: bold;
        }

        .role {
            font-size: 14px;
            color: var(--c-contrast-3);
            font-weight: bold;
        }
    }
}

//testimonial

.closing {
    padding: 120px var(--p-page-sides);

    background-color: var(--c-grey-8);

    & > div {
        text-align: center;
    }

    .icon {
        width: 72px;
        height: 32px;
        margin-bottom: 16px;
    }

    .treatment {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            max-width: 620px;
        }

        .buttons {
            display: flex;
            gap: 24px;
        }

        a {
            margin-top: 36px;
            width: 150px;
        }
    }
}

//closing

.rows {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(30%, -10%) scale(0.9);
    z-index: 2;

    .rowWrap {
        &:nth-child(1) {
            transform: translateX(45%);
            --animation-delay: 0.1s;
        }

        &:nth-child(2) {
            transform: translateX(30%);
            --animation-delay: 0.2s;
        }

        &:nth-child(3) {
            transform: translateX(15%);
            --animation-delay: 0.3s;
        }

        &:nth-child(4) {
            --animation-delay: 0.4s;
        }
    }

    //row

    @media (min-width: 768px) {
        top: 100%;
        transform: translate(80%, 20%);

        .rowWrap {
            // width: 800px;
            &:nth-child(1) {
                transform: translateX(15%);
            }

            &:nth-child(2) {
                transform: translateX(10%);
            }

            &:nth-child(3) {
                transform: translateX(5%);
            }
        }
    }

    @media (min-width: 960px) {
        transform: translate(75%, 20%);
    }

    @media (min-width: 1200px) {
        transform: translate(64%, 20%);
    }

    .row {
        padding: 24px;
        background-color: var(--c-contrast-1);
        width: 100vw;
        max-width: 1500px;
        height: 80px;
        border-radius: 8px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        opacity: 0;
    }

    @media (min-width: 1460px) {
        transform: translate(30%, 20%);

        .rowWrap {
            // width: 800px;
            &:nth-child(1) {
                transform: translateX(30%);
            }

            &:nth-child(2) {
                transform: translateX(20%);
            }

            &:nth-child(3) {
                transform: translateX(10%);
            }
        }

        .row {
            max-width: 720px;
        }
    }

    &[data-in-view='true'] {
        .row {
            animation: RowIn 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
            animation-delay: var(--animation-delay);
        }
    }

    @keyframes RowIn {
        from {
            opacity: 0;
            transform: translate3d(20%, 10%, 0);
        }

        to {
            transform: none;
            opacity: 1;
        }
    }
}

//rows

.row {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 32px;

    .vspTag {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, calc(-50% + 2px));
        --size: 24px;
    }

    .providerGroup {
        display: inline-flex;
        align-items: center;
        gap: 8px;

        .icon {
            width: 36px;
            height: 36px;
            background-color: var(--c-contrast-2);
            border-radius: 50%;
            flex-shrink: 0;
            overflow: hidden;

            .img {
                object-fit: cover;
            }
        }

        .name {
            font-size: 14px;
            font-weight: bold;
            color: var(--c-contrast-4);
            line-height: 1.25;
        }
    }
}

.rowMetric {
    line-height: 1.4;

    .label {
        font-weight: bold;
        font-size: 12px;
        color: var(--c-contrast-4);
        display: inline-flex;
        align-items: center;
        white-space: nowrap;

        .changeIndicator {
            width: 10px;
            height: 10px;
            background-color: var(--c-green);
            display: inline-block;
            margin-right: 4px;
            border-radius: 50%;

            &[data-neg='true'] {
                background-color: var(--c-red);
            }
        }
    }

    //label

    .values {
        font-size: 14px;
        display: flex;
        gap: 8px;

        .value {
            font-weight: bold;
            color: var(--c-contrast-4);
        }

        .change {
            color: var(--c-contrast-3);
        }
    }
}
