$label-z-index: 2;

.metricGroup {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    &.highlighted {
        background-color: var(--c-grey-8);
        border-radius: 8px;
        padding: 8px;
        margin: -8px;

        .valueWrap,
        .labelWrap {
            .label {
                color: var(--c-contrast-3);
            }

            .value {
                color: var(--c-contrast-4);
            }

            .percentage {
                color: var(--c-contrast-3);
            }
        }
    }

    .valueWrap,
    .labelWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        width: 100%;
        gap: 4px;
        white-space: nowrap;
        z-index: $label-z-index;

        .label {
            font-size: 12px;
            font-weight: bold;
            color: var(--c-contrast-3);
            white-space: nowrap;
            line-height: 1.25;

            &.long {
                white-space: normal;
                text-align: left;
            }
        }

        .value {
            font-size: 14px;
            color: var(--c-contrast-4);
        }

        .percentage {
            font-size: 14px;
            font-weight: normal;
            color: var(--c-contrast-3);
        }
    }

    .flagWrap {
        display: flex;
        justify-content: end;
        width: 100%;
        z-index: $label-z-index;

        .earth {
            width: 30px;
            height: 24px;
        }
    }

    .tooltip {
        word-break: break-word;
        white-space: normal;
        text-align: left;
    }

    &.large {
        row-gap: 0;

        .label {
            font-size: 14px;
        }

        .value {
            font-size: 20px;
        }

        .percentage {
            font-size: 16px;
        }

        &.highlighted {
            padding: 14px 16px;
            margin: 0;

            .valueWrap,
            .labelWrap {
                .label {
                    color: var(--c-contrast-3);
                }

                .value {
                    color: var(--c-contrast-4);
                }

                .percentage {
                    color: var(--c-contrast-4);
                }
            }
        }

        .valueWrap {
            gap: 8px;
        }
    }
}

.sortingMetricGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    column-gap: 20px;
    background-color: var(--c-grey-8);
    border-radius: 8px;
    padding: 12px;

    .valueWrap,
    .labelWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        z-index: $label-z-index;

        .label {
            font-size: 12px;
            font-weight: bold;
            color: var(--c-contrast-3);
            line-height: 1.25;

            &.long {
                white-space: normal;
                text-align: left;
            }
        }
    }

    .flagWrap {
        display: flex;
        width: 100%;
        z-index: $label-z-index;

        .earth {
            width: 30px;
            height: 24px;
        }
    }

    .tooltip {
        word-break: break-word;
        white-space: normal;
        text-align: left;
    }
}

.supportedItemsMetricGroup {
    display: flex;
    justify-content: end;
    row-gap: 4px;

    .label {
        font-size: 12px;
        font-weight: bold;
        color: var(--c-contrast-3);
        white-space: nowrap;
    }
}

.validatorMetricGroup {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;

    &:hover {
        opacity: 0.75;
        cursor: pointer;
    }

    .label {
        font-size: 12px;
        font-weight: bold;
        color: var(--c-contrast-3);
        white-space: nowrap;
    }

    .labelWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 8px;

        &.left {
            flex-direction: row;
        }

        &.right {
            flex-direction: row-reverse;
        }

        .icon {
            width: 10px;
            height: 10px;
            top: 0px;
        }
    }

    .address {
        font-size: 12px;
        font-weight: bold;
        color: var(--c-contrast-4);
    }
}

.flagMetricGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 4px;
    width: 50px;

    .label {
        width: 100%;
        text-align: left;
    }
}
